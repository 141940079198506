:root {
  --bg-color-light: #fff;
  --bg-color-dark: #333;
  --text-color-light: #333;
  --text-color-dark: #fff;
  --primary-color-light: #007bff;
  --primary-color-dark: #80bdff;
}

body.light {
  background-color: var(--bg-color-light);
  color: var(--text-color-light);
}

body.dark {
  background-color: var(--bg-color-dark);
  color: var(--text-color-dark);
}

.button.light {
  background-color: var(--primary-color-light);
}

.button.dark {
  background-color: var(--primary-color-dark);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
